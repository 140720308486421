import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { useSelector } from "react-redux";
// import { toast } from "react-toastify";
import AffiliateTransferForm from '../components/Affiliate/AffiliateTransferForm'
import { ApplicationState } from '../store'
import { format } from '../helpers/number'
import { Button, Col, Divider, Input, Row, Statistic, Typography, theme, message, Select, QRCode } from 'antd';
import { CopyOutlined } from '@ant-design/icons'
import { hexToHsl } from '../helpers/hexToHsl'
import ModalTerms from '../components/Elements/ModalTerms';

const { Text } = Typography;
const { useToken } = theme;

const Affiliate = () => {
	const { token } = useToken()

	const defalutColorPrimary = `hsl(${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l + 8}%)`
	const lightColorPrimary = `hsl(${hexToHsl(token.colorPrimary).h}, ${hexToHsl(token.colorPrimary).s}%, ${hexToHsl(token.colorPrimary).l + 30}%)`
	const userState = useSelector((state: ApplicationState) => state.user);
	const prefixState = useSelector((state: ApplicationState) => state.prefix)

	const [openTerms, setOpenTerms] = useState<boolean>(false)
	const handleModalTerms = () => setOpenTerms(!openTerms)

	const [fullUrl, setFullUrl] = useState<string>(userState.affiliate.link)
	const [isPage, setIsPage] = useState<string>('register')
	const [isRec, setIsRec] = useState<string>('')

	const [messageApi, contextHolder] = message.useMessage();
	const copyAffiliateLink = () => {
		// navigator.clipboard.writeText(userState.affiliate.link);
		navigator.clipboard.writeText(fullUrl);
		// toast.success("คัดลอกลิงก์ชวนเพื่อนสำเร็จ");
		messageApi.open({
			type: 'success',
			content: 'คัดลอกลิงก์ชวนเพื่อนสำเร็จ',
		});
	};

	// const qrRef = useRef(null);
	// const [qrRender, setQrRender] = useState(false);

	// const genQrCode = (link: string) => {
	// 	const qrCode = new QRCodeStyling({
	// 		width: 150,
	// 		height: 150,
	// 		data: link,
	// 		margin: 0,
	// 		type: "svg",
	// 		qrOptions: {
	// 			typeNumber: 0,
	// 			mode: "Byte",
	// 			errorCorrectionLevel: "Q",
	// 		},
	// 		imageOptions: {
	// 			hideBackgroundDots: true,
	// 			imageSize: 1,
	// 			margin: 0,
	// 		},
	// 		dotsOptions: {
	// 			color: "#ffffff",
	// 		},
	// 		backgroundOptions: {
	// 			color: token.colorBgBase,
	// 		},
	// 		cornersSquareOptions: {
	// 			color: "#ffffff",
	// 		},
	// 		cornersDotOptions: {
	// 			color: "#ffffff",
	// 		},
	// 	});

	// 	return qrCode
	// }

	const handlePage = (value: any) => {
		setIsPage(value)

		const _urlFull = window.location.origin + '/' + value + '?prefix=' + prefixState.info?.prefix + '&ref=' + userState.affiliate.code
		const _urlRec = isRec && isRec !== 'friend' ? '&rec=' + isRec : ''
		const urlFull = _urlFull + _urlRec

		setFullUrl(urlFull)
		// let elements = document.getElementsByClassName('invite-qrcode');
		// for (let i = 0; i < elements.length; i++) {
		// 	elements[i].innerHTML = '';
		// }

		// const qrCode = genQrCode(urlFull)
		// qrCode.append(qrRef.current!);
		// setQrRender(true);
	}

	const handleRec = (value: any) => {
		setIsRec(value)

		const _urlFull = window.location.origin + '/' + isPage + '?prefix=' + prefixState.info?.prefix + '&ref=' + userState.affiliate.code
		const _urlRec = value && value !== 'friend' ? '&rec=' + value : ''
		const urlFull = _urlFull + _urlRec

		setFullUrl(urlFull)
		// let elements = document.getElementsByClassName('invite-qrcode');
		// for (let i = 0; i < elements.length; i++) {
		// 	elements[i].innerHTML = '';
		// }

		// const qrCode = genQrCode(urlFull)
		// qrCode.append(qrRef.current!);
		// setQrRender(true);
	}

	// useEffect(() => {
	// 	if (!qrRender && qrRef.current && userState.info && fullUrl) {
	// 		const qrCode = genQrCode(fullUrl)
	// 		qrCode.append(qrRef.current!);
	// 		setQrRender(true);
	// 	}
	// }, [userState, qrRender]);

	const Aff_Credit = userState.info?.affiliate.balance;

	return (
		<>
			{contextHolder}
			<Row justify="center" className="margin-form-card">
				<Col xs={24} md={16} xl={12}>
					<div className="form-card">
						<div className="form-card-body">
							<div className="invite-qrcode-container" style={{ marginBottom: 16 }}>
								{/* <div className="invite-qrcode" ref={qrRef} /> */}
								<div className="affiliate-qrcode">
									<QRCode value={fullUrl} bordered={false} />
								</div>
							</div>

							<div>
								<Row gutter={16}>
									{prefixState?.info?.functions.homePage &&
										<Col span={12}>
											<Text type="secondary" style={{ display: 'block', marginBottom: 8 }}></Text>
											<Select
												defaultValue="register"
												optionFilterProp="children"
												onChange={(value) => handlePage(value)}
												style={{ width: '100%' }}
											>
												<Select.Option value="home" key="facebook">หน้าแรก</Select.Option>
												<Select.Option value="register" key="twitter">หน้าสมัคร</Select.Option>
											</Select>
										</Col>
									}

									<Col span={prefixState?.info?.functions.homePage ? 12 : 24}>
										<Text type="secondary" style={{ display: 'block', marginBottom: 8 }}></Text>
										<Select
											defaultValue="friend"
											optionFilterProp="children"
											onChange={(value) => handleRec(value)}
											style={{ width: '100%' }}
										>
											<Select.Option value="facebook" key="facebook">Facebook</Select.Option>
											<Select.Option value="twitter" key="twitter">Twitter</Select.Option>
											<Select.Option value="line" key="line">Line</Select.Option>
											<Select.Option value="google" key="google">Google</Select.Option>
											<Select.Option value="sms" key="sms">SMS</Select.Option>
											<Select.Option value="instargram" key="instargram">Instargram</Select.Option>
											<Select.Option value="youtube" key="youtube">Youtube</Select.Option>
											<Select.Option value="tiktok" key="tiktok">Tiktok</Select.Option>
											<Select.Option value="onlyfans" key="onlyfans">Onlyfans</Select.Option>
											<Select.Option value="telegram" key="telegram">Telegram</Select.Option>
											<Select.Option value="vk" key="vk">VK</Select.Option>
											<Select.Option value="friend" key="friend">เพื่อนแนะนำ</Select.Option>
											<Select.Option value="other" key="other">โฆษณาอื่น ๆ</Select.Option>
										</Select>
									</Col>
								</Row>
								<Divider style={{ borderBlockStart: '1px solid rgba(255, 255, 255, 0.1)' }} />
							</div>

							<div>
								<Text type="secondary" style={{ display: 'block', marginBottom: 8 }}>ลิงก์ชวนเพื่อน</Text>
								<Input.Group
									style={{ display: 'flex' }}
									compact
								>
									<Input
										value={fullUrl}
										style={{ background: '#0d0f10', color: '#fff' }}
										disabled
									/>
									<Button
										type='primary'
										icon={<CopyOutlined style={{ fontSize: 18, marginTop: 1 }} rev={undefined} />}
										style={{ height: 'auto' }}
										onClick={copyAffiliateLink}
									>
										<Text style={{ fontSize: 22 }}>คัดลอก</Text>
									</Button>
								</Input.Group>
								<Divider style={{ borderBlockStart: '1px solid rgba(255, 255, 255, 0.1)' }} />
							</div>

							<Row gutter={16} style={{ marginBottom: 16 }}>
								<Col span={12}>
									<Statistic title="ยอดปัจจุบัน" value={format(Aff_Credit || 0)} prefix="฿" />
								</Col>
								<Col span={12}>
									<Statistic title="รหัสชวนเพื่อน" value={userState.affiliate.code} />
								</Col>
							</Row>
							<AffiliateTransferForm initAmount={Aff_Credit || 0} />
							{prefixState.info?.termandcondition?.affiliateDescription && (
								<div className="terms-and-conditions-button">
									<Button className="ant-btn-dark" onClick={handleModalTerms} style={{ height: 32 }}>
										<i className="fa-solid fa-circle-info" style={{ fontSize: 16 }}></i>
										<span>กติกา</span>
									</Button>
								</div>
							)}
						</div>
					</div>
				</Col>
			</Row>

			<ModalTerms isOpen={openTerms} onClose={() => setOpenTerms(false)} description={prefixState.info?.termandcondition?.affiliateDescription || ""} />
		</>
	);
};

export default Affiliate;
