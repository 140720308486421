import React, { ComponentType, FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import store from '../../store'
import SetTheme from '../../utils/SetTheme'
import { Avatar, Row } from 'antd'
import { Redirect } from 'react-router'
import { makePath } from '../../helpers/path'
import EffectsContainer from '../Effects/EffectsContainer'
import LoadingLayout from './LoadingLayout'

const AuthLayout = (Component: ComponentType<object>): FC<RouteComponentProps> => (props: RouteComponentProps) => {
	const prefixState = store.getState().prefix

	if (props['location'].pathname === "/login") {
		const token = localStorage.getItem('x-auth')
		if (token) {
			if (prefixState?.info?.game === "ambking" || (prefixState?.info?.game === "betflix" && prefixState.info?.functions.betflixOnListgame)) {
				return <Redirect to={makePath('/launch-game')} />
			}
			return <Redirect to={makePath('/wallet')} />
		}
	} else {
		localStorage.removeItem('x-auth')
	}

	return (
		<>
			<EffectsContainer />
			<Row
				justify="center"
				align="middle"
				style={{ height: '100%' }}
				className='hero'
			>
				<SetTheme />
				<div className="auth-container ">
					{prefixState.info?.logo ? (
						<Avatar
							className="avatar-logo avatar-logo-login"
							src={prefixState.info?.logo}
							shape="square"
						/>
					) : (
						<Avatar
							className="avatar-logo"
							shape="square"
							size={125}>
							{prefixState.info?.prefix}
						</Avatar>
					)}
					<Component {...props} />
				</div>
			</Row>

			{props['location'].pathname === "/login-line" && <LoadingLayout prefix={prefixState} />}
		</>
	)
}

export default AuthLayout
