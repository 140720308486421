import React, { FC, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from '../../@types/store'
import history from '../../helpers/history'
import { ApplicationState } from '../../store'
import { requestPrefix } from '../../store/prefix/prefix.actions'
import { Result, Row, Space } from 'antd'
import { requestGamesHome } from '../../store/gamesHome/gamesHome.action'
import { Themes } from '../../utils/Theme'
import { Helmet } from 'react-helmet';
import reactManifest from 'react-manifest';

const TRACKING_ID = 'G-1LXWHZHGZM'
ReactGA.initialize(TRACKING_ID);

const PrefixContainer: FC = ({ children }) => {
	const dispatch: ThunkDispatch = useDispatch()
	const prefixState = useSelector((state: ApplicationState) => state.prefix)

	useEffect(() => {
		const unsub = history.listen((loc) => {
			ReactGA.pageview(loc.pathname + loc.search)
		})
		return () => {
			unsub()
		}
	}, [])

	useEffect(() => {
		dispatch(requestPrefix())
		dispatch(requestGamesHome(prefixState.info?.game))
	}, [dispatch, prefixState.info?.game])

	useEffect(() => {
		if (prefixState.isLoaded && prefixState.info) {
			document.title = prefixState.info.title
			const colorPrimary = prefixState?.info?.themeColor?.primaryStart || '#005de7';

			const icon = document.querySelector(`link[rel="icon"]`)
			const appleicon = document.querySelector(`link[rel="apple-touch-icon"]`)
			const shortcuticon = document.querySelector(`link[rel="shortcut icon"]`)

			const Theme_color = document.querySelector(`meta[name='theme-color']`)
			const Theme_color_msapplication = document.querySelector(`meta[name='msapplication-navbutton-color']`)
			const Theme_color_msapplication_titlecolor = document.querySelector(`meta[name='msapplication-TileColor']`)
			const Theme_color_apple_mobile_web_app = document.querySelector(`meta[name='apple-mobile-web-app-status-bar-style']`)

			// const metaOgTitle = document.querySelector(`meta[property='og:title']`)
			// const metaOgDescription = document.querySelector(`meta[property='og:description']`)
			// const metaOgImage = document.querySelector(`meta[property='og:image']`)
			// const metaOgUrl = document.querySelector(`meta[property='og:url']`)
			// const metaOgType = document.querySelector(`meta[property='og:type']`)

			// if (metaOgTitle && metaOgDescription && metaOgImage && metaOgUrl && metaOgType) {
			// 	metaOgTitle.setAttribute('content', prefixState.info.title)
			// 	metaOgDescription.setAttribute('content', "พัฒนาอย่างใส่ใจทุกการเล่นบาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง")
			// 	metaOgImage.setAttribute('content', prefixState.info.icon ? prefixState.info.icon : prefixState.info.logo)
			// 	metaOgUrl.setAttribute('content', window.location.hostname)
			// 	metaOgType.setAttribute('content', "website")
			// }

			if (icon && appleicon && shortcuticon) {
				icon.setAttribute('href', prefixState.info.icon ? prefixState.info.icon : prefixState.info.logo)
				appleicon.setAttribute('href', prefixState.info.icon ? prefixState.info.icon : prefixState.info.logo)
				shortcuticon.setAttribute('href', prefixState.info.icon ? prefixState.info.icon : prefixState.info.logo)
			}

			if (Theme_color && Theme_color_msapplication && Theme_color_msapplication_titlecolor && Theme_color_apple_mobile_web_app) {
				Theme_color.setAttribute('content', colorPrimary)
				Theme_color_msapplication.setAttribute('content', colorPrimary)
				Theme_color_msapplication_titlecolor.setAttribute('content', colorPrimary)
				Theme_color_apple_mobile_web_app.setAttribute('content', colorPrimary)
			}

		}
	}, [prefixState])

	useEffect(() => {
		const shortName = window.location.host.toLocaleUpperCase().replace('WWW.', '');

		const tagNameIos = document.createElement('meta');
		tagNameIos.name = 'apple-mobile-web-app-title';
		tagNameIos.content = shortName;
		document.head.appendChild(tagNameIos);

		const tagNameAndroid = document.createElement('meta');
		tagNameAndroid.name = 'application-name';
		tagNameAndroid.content = shortName;
		document.head.appendChild(tagNameAndroid);
		const manifestDetails = {
			"name": shortName,
			"short_name": shortName
		};

		reactManifest.update(manifestDetails, "#manifest-placeholder");

		return () => {
			document.head.removeChild(tagNameIos);
			document.head.removeChild(tagNameAndroid);
		};
	}, []);

	if (!prefixState.isLoaded) {
		return (
			<></>
		)
	}

	if (prefixState.isLoaded && !prefixState.info) {
		return (
			// <Row justify="center" align="middle" style={{ height: '100%', background: '#101010', overflow: 'hidden' }}>
			// 	<Result
			// 		status="warning"
			// 		title="เว็บไซต์ดังกล่าวไม่สามารถใช้งานได้"
			// 		subTitle="กรุณาตรวจสอบข้อมูลอีกครั้ง"
			// 	/>
			// </Row>
			<Row justify="center" align="middle" style={{ height: '100%', background: '#B6BBC4', overflow: 'hidden' }}>
				<Space direction="vertical" align="center">
					<svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="150" xmlns="http://www.w3.org/2000/svg" ><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#ffe59a" /><stop offset="1" stop-color="#ffffd5" /></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="105" y2="407"><stop offset="0" stop-color="#fd5900" /><stop offset="1" stop-color="#ffde00" /></linearGradient><g><g><circle cx="256" cy="256" fill="url(#SVGID_1_)" r="256" /></g><g><g><path d="m184.941 282.647h-53.294c-4.905 0-8.882 3.976-8.882 8.882v106.588c0 4.905 3.977 8.882 8.882 8.882h62.176v-115.47c.001-4.906-3.977-8.882-8.882-8.882zm-17.764 53.294c-4.905 0-8.882-3.978-8.882-8.882 0-4.907 3.977-8.882 8.882-8.882s8.882 3.976 8.882 8.882c0 4.905-3.977 8.882-8.882 8.882zm133.235-136.185v82.891h53.294v-82.891c15.863-9.232 26.647-26.227 26.647-45.903 0-16.989-7.999-34.033-20.407-46.282-5.6-5.53-15.122-1.549-15.122 6.32v31.08l-17.765 17.765-17.765-17.765v-31.08c0-7.871-9.522-11.849-15.122-6.32-12.408 12.249-20.407 29.292-20.407 46.282 0 19.676 10.784 36.67 26.647 45.903zm71.058 100.656h-79.941c-4.905 0-8.882-3.978-8.882-8.883v-35.529c0-4.906-3.977-8.882-8.882-8.882-14.717 0-26.647 11.93-26.647 26.647v26.647h-35.529v106.588h142.118c19.622 0 35.529-15.907 35.529-35.529v-53.294c-.001-9.812-7.954-17.765-17.766-17.765z" fill="url(#SVGID_2_)" /></g></g></g></svg>
					<h1 style={{ textAlign: 'center', color: "#101010" }}>ขณะนี้อยู่ระหว่างการปิดปรับปรุงอัพเดทระบบ</h1>
					<p style={{ textAlign: 'center', color: "#101010" }}>หากดำเนินการแก้ไขเสร็จเรียบร้อย จะแจ้งให้ทราบอีกครั้ง<br />ขออภัยในความไม่สะดวกค่ะ</p>
				</Space>
			</Row>
		)
	}
	if (prefixState.isLoaded && prefixState.info?.maintenance) {
		return (
			<Row justify="center" align="middle" style={{ height: '100%', background: '#101010', overflow: 'hidden' }}>
				<Space direction="vertical" align="center">
					<svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="150" xmlns="http://www.w3.org/2000/svg" ><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#ffe59a" /><stop offset="1" stop-color="#ffffd5" /></linearGradient><linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="105" y2="407"><stop offset="0" stop-color="#fd5900" /><stop offset="1" stop-color="#ffde00" /></linearGradient><g><g><circle cx="256" cy="256" fill="url(#SVGID_1_)" r="256" /></g><g><g><path d="m184.941 282.647h-53.294c-4.905 0-8.882 3.976-8.882 8.882v106.588c0 4.905 3.977 8.882 8.882 8.882h62.176v-115.47c.001-4.906-3.977-8.882-8.882-8.882zm-17.764 53.294c-4.905 0-8.882-3.978-8.882-8.882 0-4.907 3.977-8.882 8.882-8.882s8.882 3.976 8.882 8.882c0 4.905-3.977 8.882-8.882 8.882zm133.235-136.185v82.891h53.294v-82.891c15.863-9.232 26.647-26.227 26.647-45.903 0-16.989-7.999-34.033-20.407-46.282-5.6-5.53-15.122-1.549-15.122 6.32v31.08l-17.765 17.765-17.765-17.765v-31.08c0-7.871-9.522-11.849-15.122-6.32-12.408 12.249-20.407 29.292-20.407 46.282 0 19.676 10.784 36.67 26.647 45.903zm71.058 100.656h-79.941c-4.905 0-8.882-3.978-8.882-8.883v-35.529c0-4.906-3.977-8.882-8.882-8.882-14.717 0-26.647 11.93-26.647 26.647v26.647h-35.529v106.588h142.118c19.622 0 35.529-15.907 35.529-35.529v-53.294c-.001-9.812-7.954-17.765-17.766-17.765z" fill="url(#SVGID_2_)" /></g></g></g></svg>
					<h1 style={{ textAlign: 'center', color: "#fff" }}>ขณะนี้อยู่ระหว่างการปิดปรับปรุงอัพเดทระบบ</h1>
					<p style={{ textAlign: 'center', color: "#fff" }}>หากดำเนินการแก้ไขเสร็จเรียบร้อย จะแจ้งให้ทราบอีกครั้ง<br />ขออภัยในความไม่สะดวกค่ะ</p>
				</Space>
			</Row>
		)
	}
	return (
		<>
			<Helmet>
				<meta property="og:title" content={prefixState.info !== undefined ? prefixState.info.title : ''} />
				<meta property="og:description" content="พัฒนาอย่างใส่ใจทุกการเล่นบาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง" />
				<meta property="og:image" content={prefixState.info !== undefined ? prefixState.info.icon ? prefixState.info.icon : prefixState.info.logo : ''} />
				<meta property="og:url" content={window.location.hostname} />
				<meta property="og:type" content="website" />
			</Helmet>
			{children}
		</>
	)
}

export default PrefixContainer
