import React, { FC } from 'react'
import { format } from '../../helpers/number'
import { Button, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Text } = Typography

interface TurnWidgetProps {
	isTurnover: boolean
	credit: number
	totalTurn: number
	currentTurnover: number
	turnover: number
	showTurnover: boolean
	refetchCredit?: () => void
}

const TurnWidget: FC<TurnWidgetProps> = ({ isTurnover, credit, totalTurn, currentTurnover, turnover, showTurnover, refetchCredit }) => {
	return (
		// ถ้ามี เทิร์นโอเวอร์ ให้ใส่ class "turn-balance-item"
		<div className="wallet-other-card">

			{!showTurnover ? (
				<div className='wallet-other-card-inner'>
					<Text type="secondary">ยอดเทิร์นเครดิต</Text>
					<div className="wallet-other-card-amount">
						<span>฿ {format(totalTurn)}</span>
					</div>
				</div>
			) : (
				<>
					<div className='wallet-other-card-inner'>
						<Text type="secondary">ยอดเทิร์นโอเวอร์</Text>
						<div className="wallet-other-card-amount">
							<span>฿ {format(currentTurnover)}</span>
							<span className="wallet-other-card-totalturn">/ {format(turnover)}</span>
						</div>
					</div>
					<Button
						type="primary"
						className="ant-btn-secondary reward-cashback"
						onClick={refetchCredit}
						icon={true ?
							<i className="fa-sharp fa-regular fa-arrows-rotate" style={{ fontSize: 18 }}></i> :
							<LoadingOutlined style={{ fontSize: 18, marginLeft: 6 }} rev={undefined} />
						}
					/>
				</>
			)}
		</div>
	)
}

export default TurnWidget
