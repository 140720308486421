import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar } from 'antd';
import { makePath } from "../helpers/path";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

const Play = () => {
    const loc = useLocation();
    const params = new URLSearchParams(loc.search);
    const gameParam = params.get('game');
    const paramsGameUrl = gameParam ? atob(gameParam) : '';
    const gamePathParam = params.get('path');
    const paramsGamePath = gamePathParam ? atob(gamePathParam) : '';

    const prefixState = useSelector((state: ApplicationState) => state.prefix);

    if (paramsGameUrl === '') {
        window.location.href = makePath(paramsGamePath);
    }

    const goBack = () => {
        if (paramsGamePath !== null) {
            window.location.href = makePath(paramsGamePath);
        }
    };

    useEffect(() => {
        const handleIframeMessage = (event: MessageEvent) => {
            const message = event.data;
            if (message.type === 'Window.Redirect') {
                window.location.href = makePath(paramsGamePath)
            }
        };

        window.addEventListener('message', handleIframeMessage);

        return () => {
            window.removeEventListener('message', handleIframeMessage);
        };
    }, []);

    return (
        <>
            <div className="topbar-game">
                <span
                    className="material-symbols-rounded"
                    onClick={goBack}
                    style={{ fontSize: 32, color: 'var(--color-text-primary-bg)', padding: '8px 8px 8px 4px', cursor: 'pointer' }}
                >
                    arrow_back
                </span>
                <Link to={makePath('/wallet')}>
                    {prefixState.info?.logo ? (
                        <Avatar
                            className="avatar-logo avatar-logo-topbar"
                            src={prefixState.info?.logo}
                            shape="square"
                        />
                    ) : (
                        <div className="prefix-name">{prefixState.info?.prefix}</div>
                    )}
                </Link>
            </div>
            <iframe
                src={paramsGameUrl}
                title="Game"
                allowFullScreen
                className={`iframe-game`}
            ></iframe>
        </>

    );
};

export default Play;
