import React, { FC, useCallback, useState } from 'react'
import { AMBKingGameProviderConfig } from '../../../entities/AMBKing'
import { useHistory, useLocation } from 'react-router-dom';
import { makePath } from '../../../helpers/path';
import { toast } from 'react-toastify';
import api from '../../../helpers/api'

interface ImageProps {
    src: string;
    alt: string;
    className?: string;
    placeholderSrc: string;
}

const ImageWithPlaceholder: FC<ImageProps> = ({ src, alt, className, placeholderSrc }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(src);

    const handleImageError = () => {
        // ถ้ารูปโหลดไม่ได้ ให้ใช้รูปทดแทน
        setImageSrc(placeholderSrc);
    };

    return (
        <img
            src={imageSrc as string}
            alt={alt}
            className={className}
            onError={handleImageError}
        />
    );
};

interface ProviderListProps {
    config: Record<string, AMBKingGameProviderConfig> | any
    type: string

    setProvider: (name: string) => void
    setGameType: (name: string) => void
}

interface BetflixLoginResponse {
    url: string
}

const ProviderList: FC<ProviderListProps> = ({ config, type, setProvider, setGameType }) => {

    const location = useLocation();
    const history = useHistory();

    const initBetflixGame = useCallback(async () => {
        try {
            const { url } = await api.post<void, BetflixLoginResponse>('/login/betflix')
            
            if (url) {
                const params = new URLSearchParams(location.search);
                params.set('path', '/wallet')
                history.push({ search: params.toString() });
                window.location.href = url
            } else {
                toast.error('login failed')
                history.push(makePath('/wallet'))
            }
        } catch ({ message }: any) {
            history.push(makePath('/login'))
        }
    }, [history]) //eslint-disable-line

    return (
        <div className="ambking-grid-row">
            {Object.values(config).map((itemProvider: any, indexProvider: any) => (
                <div
                    key={`${itemProvider.provider}-${indexProvider}`}
                    className="game-card"
                    onClick={() => {
                        setProvider(itemProvider.provider)
                        initBetflixGame()
                    }}
                >
                    <div className="game-card-img-container">
                        <ImageWithPlaceholder src={`/provider-cover/${type}/${itemProvider.provider.toLowerCase()}.webp`} alt={itemProvider.providerName} className="game-card-img" placeholderSrc="/image-placeholder.png" />
                        <span className="game-card-name">{itemProvider.providerName}</span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ProviderList
