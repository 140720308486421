import React, { FC } from 'react';

interface Props {
    labelType: 'hot' | 'recommend' | 'new' | 'flashsale' | 'unconditional';
}

const PromotionLabel:FC<Props> = ({ labelType })=> {
    let labelText = '';
    let backgroundColor = '';

    switch (labelType) {
        case 'new':
            labelText = 'มาใหม่';
            backgroundColor = '#f00000';
            break;
        case 'hot':
            labelText = 'ยอดฮิต';
            backgroundColor = '#f00000';
            break;
        case 'flashsale':
            labelText = 'Flash Sale';
            backgroundColor = '#f00000';
            break;
        case 'recommend':
            labelText = 'แนะนำ';
            backgroundColor = '#f00000';
            break;
        case 'unconditional':
            labelText = 'ไม่มีกฎ';
            backgroundColor = '#f00000';
            break;
        default:
            labelText = 'ไม่ระบุ';
            backgroundColor = '#000000';
            break;
    }

    return (
        <div className="promotion-slider-item-label" style={{ background: backgroundColor }}>
            <span>{labelText}</span>
            <div className="promotion-slider-item-label-conner" style={{ border: `transparent transparent ${backgroundColor} transparent` }} />
        </div>
    );
};

export default PromotionLabel;