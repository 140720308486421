import { Divider, Modal, Space, Typography, } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import 'react-lazy-load-image-component/src/effects/blur.css';

const { Text } = Typography

interface ModalTermsAllProps {
    isOpen: boolean
    onClose: () => void
}
const ModalTermsAll: FC<ModalTermsAllProps> = ({ isOpen, onClose }) => {
    const prefixState = useSelector((state: ApplicationState) => state.prefix)
    const [affiliate, setAffiliate] = useState<string>('')
    const [cashback, setCashback] = useState<string>('')
    const [wheel, setWheel] = useState<string>('')
    const [termandcondition, setTermandcondition] = useState<string>('')
    const prefix = prefixState.info

    useEffect(() => {
        if (isOpen) {
            setTermandcondition(prefix?.termandcondition?.termandconditionDescription || '')
            setAffiliate(prefix?.termandcondition?.affiliateDescription || '')
            setCashback(prefix?.termandcondition?.cashbackDescription || '')
            setWheel(prefix?.termandcondition?.wheelDescription || '')
        }
    }, [isOpen, prefix])

    return (
        <Modal
            className="modal-terms"
            open={isOpen}
            title="เงื่อนไขและกติกา"
            centered
            onCancel={() => onClose()}
            footer={false}
        >
            <div className="">
                <Space direction="vertical">
                    {termandcondition.split('\n').map((line, index) => <Text key={`termandcondition-${index}`} style={{ lineHeight: index === 0 ? "2" : "0", fontWeight: index === 0 ? 800 : 0 }}>{line}</Text>)}
                    {cashback && <Divider />}
                    {cashback.split('\n').map((line, index) => <Text key={`cashback-${index}`} style={{ lineHeight: index === 0 ? "2" : "0", fontWeight: index === 0 ? 800 : 0 }}>{line}</Text>)}
                    {affiliate && <Divider />}
                    {affiliate.split('\n').map((line, index) => <Text key={`affiliate-${index}`} style={{ lineHeight: index === 0 ? "2" : "0", fontWeight: index === 0 ? 800 : 0 }}>{line}</Text>)}
                    {wheel && <Divider />}
                    {wheel.split('\n').map((line, index) => <Text key={`wheel-${index}`} style={{ lineHeight: index === 0 ? "2" : "0", fontWeight: index === 0 ? 800 : 0 }}>{line}</Text>)}
                </Space>
            </div>
        </Modal>
    )
}

export default ModalTermsAll
