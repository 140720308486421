import React, { FC } from 'react';
import { Tag } from 'antd';

interface Props {
    labelType: 'hot' | 'recommend' | 'new' | 'flashsale' | 'unconditional';
}

const PromotionListItemLabel: FC<Props> = ({ labelType }) => {
    let labelText = '';
    let backgroundColor = '';

    switch (labelType) {
        case 'new':
            labelText = 'มาใหม่';
            backgroundColor = 'red';
            break;
        case 'hot':
            labelText = 'ยอดฮิต';
            backgroundColor = 'red';
            break;
        case 'flashsale':
            labelText = 'Flash Sale';
            backgroundColor = 'red';
            break;
        case 'recommend':
            labelText = 'แนะนำ';
            backgroundColor = 'red';
            break;
        case 'unconditional':
            labelText = 'ไม่มีกฎ';
            backgroundColor = 'red';
            break;
        default:
            labelText = 'ไม่ระบุ';
            backgroundColor = '#000000';
            break;
    }

    return (
        <div style={{ marginBottom: 16 }}>
            <Tag color={backgroundColor} style={{ lineHeight: '1.15', background: '#2a1215', borderColor: '#58181c' }}>{labelText}</Tag>
        </div>
    );
};

export default PromotionListItemLabel;