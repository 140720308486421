// import qs from 'querystring'
import React from 'react'
import { useSelector } from 'react-redux'
// import { Redirect, useLocation } from 'react-router'
import AMBSuperAPIGame from '../components/LaunchGame/AMBSuperAPIGame'
import Askmebet from '../components/LaunchGame/Askmebet'
import BetflixGame from '../components/LaunchGame/BetflixGame'
import DefaultGame from '../components/LaunchGame/DefaultGame'
import JokerTechGame from '../components/LaunchGame/JokerTechGame'
import PGSlotGame from '../components/LaunchGame/PGSlotGame'
import SuperSlotGame from '../components/LaunchGame/SuperSlotGame'
import UFABetGame from '../components/LaunchGame/UFABetGame'
import UsunGame from '../components/LaunchGame/UsunGame'
import Superlot from '../components/LaunchGame/Superlot'
import GClubGame from '../components/LaunchGame/GClubGame'
// import AMBKing from '../components/LaunchGame/AMBKing'
import AMBKing1 from '../components/LaunchGame/AMBKing1'
// import { makePath } from '../helpers/path'
import { ApplicationState } from '../store'
import BetflixHome from '../components/LaunchGame/BetflixHome'
import LTM888 from '../components/LaunchGame/LTM888'

const LaunchGame = () => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefix = prefixState.info!,
		member = userState.info!

	// const location = useLocation()
	// const query = qs.parse(location.search.slice(1))
	// if (!query.t || Date.now() > Number(query.t)) {
	// 	return <Redirect to={makePath('/wallet')} />
	// }
	// if (prefix.prefix.toUpperCase() === "SHABU") {
	// 	return <AMBKing1 />
	// }

	switch (prefix.game) {
		case 'superlot':
			return <Superlot member={member} />
		case 'usun':
			return <UsunGame />
		case 'pgslot':
			return <PGSlotGame />
		case 'betflix':
			if (prefixState.info?.functions.betflixOnListgame) {
				return <BetflixHome />
			} else {
				return <BetflixGame />
			}
		case 'ambking':
			return <AMBKing1 />
		case 'ltm888':
			return <LTM888 />
		case 'ambbet':
		case 'wingbet':
		case '2goalbet':
			return <Askmebet prefix={prefix} member={member} />
		case 'ufa':
			return <UFABetGame member={member} />
		case 'gclub168':
			return <GClubGame member={member} />
		case 'joker123':
		case 'slotxo':
			return <JokerTechGame />
		case 'superslot':
			return <SuperSlotGame member={member} />
		case 'ambsuperapi':
			return <AMBSuperAPIGame />
		default:
			return <DefaultGame gameURL={prefix.gameLink} />
	}
}

export default LaunchGame
