import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import { toast } from 'react-toastify'
import { ApplicationState } from '../../store'
import { getBankName, getBankColor, Banks } from '../../helpers/bank'
import { format } from '../../helpers/number'
import { Button, Form, Modal, InputNumber, Typography } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import api from '../../helpers/api'
import { vizpayOrderInquiry } from '../../@types/vizpay'
import BankQrCodeCapture from './BankQrCodeCapture'
import { Member } from '../../@types/member'

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
	onOpen: () => void
}

const BankQrCode: FC<Props> = ({ isOpen, onClose, onOpen }) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [qrCodeModal, setQrCodeModal] = useState<boolean>(false);
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [amount, setAmount] = useState<number>(0) // จำนวนเงินที่ต้องการเติม
	const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false) // สถานะ : มีธุรกรรมที่กำลังดำเนินการอยู่
	const [isNewProgress, setIsNewProgress] = useState<boolean>(false) // สถานะ : มีธุรกรรมที่กำลังดำเนินการอยู่
	const [isTimeOut, setIsTimeOut] = useState<boolean>(false)  // สถานะ : หมดเวลาทำรายการ
	const [isSuccess, setIsSuccess] = useState<boolean>(false)  // สถานะ : ทำรายการสำเร็จ
	const userState = useSelector((state: ApplicationState) => state.user)
	const info = userState.info
	const [timer, setTimer] = useState<number>(0);
	const [stoppedTimer, setStoppedTimer] = useState<boolean>(false);
	const [depositQRCode, setDepositQRCode] = useState<any>()

	const secondsToMinutes = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')} นาที`;
		return formattedTime;
	}

	const handleSubmit = async () => {
		setSubmitting(true)
		form.validateFields()
			.then(async (values: any) => {
				if (values.amount <= 0) {
					setSubmitting(false)
					return false
				}
				try {

					setAmount(values.amount)

					const res = await api.post('/deposit/vizpay-qr', {
						confirmGenerateQR: isNewProgress,
						amount: values.amount
					})

					setSubmitting(false)
					onClose()

					const dateString = res.data.timeout.date
					const inDate = new Date(dateString)

					const currentDate = new Date()

					// คำนวณระยะเวลาและแปลงให้อยู่ในรูปของวินาที
					const timeInSeconds = (currentDate.getTime() - inDate.getTime()) / 1000
					const timeCurrent = timeInSeconds * -1
					const timeFinal = parseInt(timeCurrent.toString(), 10)

					setTimer(timeFinal)

					setDepositQRCode(res.data)
					setQrCodeModal(true)
					setStoppedTimer(false)
				} catch (error: any) {
					toast.error(error.message as string)
					setSubmitting(false)
					setIsTransactionInProgress(true)
				}
			}).catch((errorInfo) => {
				setSubmitting(false)
			});
	}
	const handleTimeOut = () => {
		setQrCodeModal(false)
		setIsTimeOut(false)
		onOpen()
	}

	const getStatusProcess = () => {
		const res = api.post<vizpayOrderInquiry>('/deposit/vizpay-order-inquiry', {  // eslint-disable-line
			orderId: depositQRCode.order_id,
			refId: depositQRCode.ref_id
		}).then(data => {
			if (data.data.status === 'SUCCESS') {
				setStoppedTimer(true)
				setIsSuccess(true)
			}
		})
	}

	const onCloseProcess = () => {
		setIsTransactionInProgress(false)
		setStoppedTimer(true)
		setIsNewProgress(false)
		setIsTimeOut(false)
	}

	useEffect(() => {
		if (qrCodeModal && !stoppedTimer) {
			const countdown = setInterval(() => {
				if (timer === 0) {
					clearInterval(countdown);
					setIsTransactionInProgress(false)
					setIsNewProgress(false)
					setIsTimeOut(true)
				} else {
					setTimer(timer - 1);
				}
			}, 1000);

			return () => clearInterval(countdown);
		}
	}, [qrCodeModal, timer, stoppedTimer]); // eslint-disable-line

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'amount': amount
			})
		}

		if (qrCodeModal) {
			const getStatusInterval = setInterval(() => {
				getStatusProcess()
			}, 5000);

			return () => clearInterval(getStatusInterval);
		}
	}, [isOpen, amount, form, qrCodeModal]) // eslint-disable-line

	return (
		<>
			{/* Step 1 */}
			<Modal
				className="ant-modal-deposit"
				open={isOpen}
				title="เติมเงินผ่าน QR Code"
				centered
				onCancel={() => { !isSubmitting && onClose(); onCloseProcess() }}
				footer={null}
			>
				{isTransactionInProgress ? (
					// เช็ควา่ามีธุรกรรมที่กำลังดำเนินการอยู่หรือไม่
					<div style={{ textAlign: 'center', padding: '12px 0 8px' }}>
						<LoadingOutlined rev={undefined} style={{ fontSize: 56, marginBottom: 16 }} />
						<div style={{ marginBottom: 8 }}>
							<Text>มีธุรกรรมที่กำลังดำเนินการอยู่</Text>
						</div>
						<div className="notice-card danger" style={{ textAlign: 'left' }}>
							<Text className="notice-card-text" style={{ paddingLeft: 0 }}>
								หาก <span style={{ color: '#dc4446' }}>โอนเข้า QR Code</span> ก่อนหน้านี้ แล้ว<span style={{ color: '#dc4446' }}>สร้าง QR Code ใหม่</span> จะทำให้<span style={{ color: '#dc4446' }}>ระบบไม่ได้รับเงิน</span>ที่คุณโอน
							</Text>
						</div>
						<div className="notice-card danger" style={{ textAlign: 'left' }}>
							<Text className="notice-card-text" style={{ paddingLeft: 0 }}>
								หากโอนเงินใน <span style={{ color: '#dc4446' }}>QR Code ก่อนหน้า</span> แล้วยอดไม่เข้าให้ <span style={{ color: '#dc4446' }}>ติดต่อเจ้าหน้าที่ </span> ก่อนสร้าง QR Code ใหม่
							</Text>
						</div>
						<Button type="primary" block onClick={() => { setIsTransactionInProgress(false); setIsNewProgress(true) }}>สร้าง QR Code ใหม่</Button>
					</div>
				) : (
					<Form
						form={form}
						name="truewalletVoucherForm"
						layout="vertical"
						hideRequiredMark
					>
						<Form.Item
							name="amount"
							label="จำนวนเงินที่ต้องการเติม"
							rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ต้องการเติม' }]}
							style={{ marginBottom: 0 }}
						>
							<InputNumber
								placeholder="0"
								inputMode="numeric"
								prefix="฿"
								controls={false}
								formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
								parser={(value) => (typeof value === 'string' ? value.replace(/[^\d]/g, '') : '')}
								onKeyPress={(event) => {
									if (!/^[0-9.,\b]+$/.test(event.key) || event.key === '.' || event.key === ',') {
										event.preventDefault();
									}
								}}
								style={{ width: '100%' }}
							/>
						</Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							loading={isSubmitting}
							disabled={isSubmitting}
							onClick={handleSubmit}
							style={{ margin: '16px 0 12px' }}
							block
						>
							เติมเงิน
						</Button>
					</Form>
				)}
			</Modal >

			{/* Step 2 */}
			<Modal
				open={qrCodeModal}
				title="เติมเงินผ่าน QR Code"
				footer={null}
				onCancel={() => { setQrCodeModal(false); onCloseProcess() }}
				centered
			>
				{!isSuccess && !isTimeOut && (
					<>
						<img src={depositQRCode?.image} alt={`qrCode`} style={{ display: 'block', margin: '0 auto', width: 150 }} />
						<div style={{ textAlign: 'center', marginTop: 8, marginBottom: 8 }}>
							<div>
								<Text>ยอดเงิน <strong>{format(amount, 2)}</strong> บาท</Text>
							</div>
							<div>
								<Text type="secondary"><small>สแกนภายใน {secondsToMinutes(timer)}</small></Text>
							</div>
						</div>
						<div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center' }}>
							<BankQrCodeCapture
								qrCodeUrl={depositQRCode?.image}
								bankLogoUrl={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
								info={info as Member}
								amount={format(amount, 2)}
							/>
						</div>
						<div className="bank-card" style={{ marginBottom: 16 }}>
							<div
								className="bank-card-logo"
								style={{ boxShadow: `0 8px 12px -4px ${getBankColor(info?.bankCode)}` }}
							>
								<img
									src={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
									alt={getBankName(info?.bankCode)}
								/>
							</div>
							<div className="bank-card-info">
								<div><small>{getBankName(info?.bankCode)}</small></div>
								<div className="bank-deposite-account">{info?.bankNumber}</div>
								<div><small>{info?.firstName} {info?.lastName}</small></div>
							</div>
						</div>
						<div className="notice-card danger">
							<Text className="notice-card-text" style={{ paddingLeft: 0 }}>
								ใช้ <span style={{ color: '#dc4446' }}>ชื่อ/เลขบัญชี</span> ที่สมัคร <span style={{ color: '#dc4446' }}>สแกน QR Code </span>เท่านั้น
							</Text>
						</div>
					</>
				)}

				{isSuccess && (
					// ทำรายการสำเร็จ
					<div style={{ textAlign: 'center', padding: '24px 0' }}>
						<CheckCircleOutlined rev={undefined} style={{ color: '#33cb00', fontSize: 56, marginBottom: 16 }} />
						<div>
							<Text style={{ fontSize: 28 }}>รับเงินเข้าสำเร็จ {amount} บาท สำเร็จ</Text>
						</div>
						<div style={{ marginBottom: 12 }}>
							<Text type="secondary" style={{ fontSize: 28 }}><small>ระบบกำลังเติมเงินเข้ากระเป๋า กรุณารอสักครู่</small></Text>
						</div>
						<Button className="ant-btn-dark" onClick={() => history.push(makePath('/wallet'))}>กลับหน้าแรก</Button>
					</div>
				)}

				{isTimeOut && (
					// หมดเวลาทำรายการ
					<div style={{ textAlign: 'center', padding: '24px 0' }}>
						<ExclamationCircleOutlined rev={undefined} style={{ color: '#dc0303', fontSize: 56, marginBottom: 16 }} />
						<div style={{ marginBottom: 12 }}>
							<Text>หมดเวลาทำรายการ</Text>
						</div>
						<Button className="ant-btn-dark" onClick={handleTimeOut}>ทำรายการใหม่</Button>
					</div>
				)}
			</Modal>
		</>
	)
}

export default BankQrCode
