import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import BankDepositItem from '../components/Deposit/BankDepositItem'
import { ApplicationState } from '../store'
import { Avatar, Modal, Typography } from 'antd'
import { Banks, getBankColor, getBankName } from '../helpers/bank'

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
}

const BankDeposit: FC<Props> = ({ isOpen, onClose }) => {
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)
	const userState = useSelector((state: ApplicationState) => state.user)

	const depositToBanks = depositToList.filter((bank) => bank.bank !== 'truewallet')
	if (depositToBanks.length <= 0) {
		return <Redirect to="/deposit" />
	}

	const bankMember = Banks.filter(bankName => bankName.key === userState.info?.bankCode.toUpperCase())[0]

	return (
		<Modal
			className="ant-modal-deposit"
			open={isOpen}
			title="โอนผ่านธนาคาร"
			centered
			onCancel={onClose}
			footer={null}
		>
			<div className="notice-card warning">
				<div className="notice-card-icon">
					<img src="/icons/new/notice.svg" alt="notice icon" />
				</div>
				<Text className="notice-card-text">
					ระบบจะเติมเงินอัตโนมัติเข้ายูสเซอร์ของท่าน <Text type="warning"><strong>ภายใน 30 วินาที</strong></Text>
				</Text>
			</div>
			{/* <div className="notice-card danger">
				<div className="notice-card-icon">
					<img src="/icons/new/danger.svg" alt="notice icon" />
				</div>
				สามารถโอนเงินเข้าธนาคารโดยใช้ <Text type="danger"><strong>ชื่อบัญชี/เลขบัญชี</strong></Text> ที่สมัครเข้ามาเท่านั้น
				<Text className="notice-card-text">
					<div style={{ display: 'flex' }}>
						<img
							src={bankMember.image}
							alt={getBankName(userState.info?.bankCode)}
							style={{ width: 28, marginRight: 8 }}
						/>
						<div>
							{userState.info?.bankNumber}<br />
							{userState.info?.firstName} {userState.info?.lastName}
						</div>
					</div>
					<span>กรุณาใช้บัญชีธนาคารนี้โอนเข้ามาเท่านั้น</span>
				</Text>
			</div> */}

			<div className="bank-deposit-card bank-form" style={{ marginBottom: 16 }}>
				<div
					className="bank-card-logo"
					style={{ boxShadow: `0 8px 12px -4px ${getBankColor(userState.info?.bankCode)}` }}
				>
					<img
						src={bankMember.image}
						alt={getBankName(userState.info?.bankCode)}
					/>
				</div>
				<div className="bank-card-info">
					<div><Text type="secondary"><small>{getBankName(userState.info?.bankCode)}</small></Text></div>
					<Text className="bank-deposite-account">{userState.info?.bankNumber}</Text>
					<div><Text type="secondary"><small>{userState.info?.firstName} {userState.info?.lastName}</small></Text></div>
				</div>
			</div>
			<div style={{ margin: '16px 0', textAlign: 'center' }}>
				<i className="fa-solid fa-arrow-down"></i>
			</div>
			{depositToBanks.map((bank) => (
				<BankDepositItem bank={bank} key={bank.accountNumber} />
			))}
		</Modal>
	)
}

export default BankDeposit
